import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import HybridHeader from "./hybrid-header"
import "@fortawesome/fontawesome-svg-core/styles.css"

config.autoAddCss = false;
library.add(fab, fas);

const Hybrid = ({ children }) => {

    const data = useStaticQuery(graphql`
    query HybridTitleQuery {
        site {
            siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <>
            <HybridHeader siteTitle={data.site.siteMetadata.title} theme={`dark`} />
            <main className={`hybrid-app`}>{children}</main>
        </>
    )
}

Hybrid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hybrid
