import React from "react"
import {Container} from 'react-bootstrap'
import {injectIntl} from "gatsby-plugin-react-intl"
import logo from "../images/notificare-logo.svg"

function HybridHeader({ intl, siteTitle, theme }) {
    return(
        <div className={`hybrid-header ` + theme }>
            <Container>
                <div className={`hybrid-navigation-bar`}>
                    <span className={`brand`}>
                        <img alt={siteTitle} src={logo} width="224" height="34" />
                        <div id={`appBadge`}></div>
                    </span>
                </div>
            </Container>
        </div>
    )

}

export default injectIntl(HybridHeader)



