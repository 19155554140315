import React from "react"
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import { injectIntl } from "gatsby-plugin-react-intl"
import Seo from "../../../components/seo"
import InstructionsData from "../../../content/hybrid-app/donwload-android"
import Hybrid from "../../../components/hybrid"
import Instructions from "../../../components/misc/instructions"

const HybridAppDownloadAndroidPage = ({ intl }) => (
    <Hybrid>
        <Seo lang={intl.locale}
             title={intl.formatMessage({ id: "pages.home.title" })}
             description={intl.formatMessage({ id: "pages.home.description" })}
        />
        <Instructions content={InstructionsData[intl.locale]} />
    </Hybrid>
)

export default injectIntl(HybridAppDownloadAndroidPage)
